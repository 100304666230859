import axios from "axios";
import { useState } from "react";

import { Typography, Stack, Box, Button, Grid, Alert } from "@mui/material";
import IMAGES from "shared/images";
import { COLORS } from "styles/theme";
import {
  InfoContainer,
  InfoItem,
  ContactHeaderInfo,
  ContactSubHeaderInfo,
  ContactIcon,
  ContactFormCard,
  ContactHeaderForm,
  ContactSubHeaderForm,
  ContactFormInput,
  ContactInfoCardLeft,
} from "./contact.styles";

const InfoContainerData = {
  Phone: {
    icon: IMAGES.PhoneIcon,
    Text: "+65 8899 1566 ",
  },
  Email: {
    icon: IMAGES.EmailIcon,
    Text: "sales@tradesocio.com",
  },
  Locations: {
    icon: IMAGES.LocationIcon,
    Text: "2 Venture Drive, #12-01 Vision Exchange,Singapore 608526",
  },
};

function Contact() {
  const [formErrors, setFormError] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const { firstName, lastName, email, phoneNumber, message } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowSuccess(false);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_ZOHO_BACKEND}/lead/create`;

    try {
      const requestBody = {
        ...formData,
      };
      await axios.post(url, requestBody);
      setShowSuccess(true);
      setFormError([]);
      setFormData(initialFormState);
    } catch (error) {
      setFormError([...error.response.data.message]);
    }
  };
  return (
    <Box position="relative">
      <Stack component="section" direction="row-reverse">
        <Grid container>
          <Grid
            item
            xs={12}
            lg={4}
            position="relative"
            display="flex"
            justifyContent="center"
          >
            <ContactInfoCardLeft>
              <ContactHeaderInfo>Contact Us</ContactHeaderInfo>
              <ContactSubHeaderInfo>
                Feel free to contact us!
              </ContactSubHeaderInfo>

              <InfoContainer>
                <Stack>
                  <InfoItem>
                    <ContactIcon
                      src={InfoContainerData.Phone.icon}
                      alt="Phone"
                    />
                    <Typography
                      component="a"
                      variant="body1"
                      sx={{
                        fontSize: "20px",
                      }}
                      href={`tel:${InfoContainerData.Phone.Text} `}
                    >
                      {InfoContainerData.Phone.Text}
                    </Typography>
                  </InfoItem>
                  <InfoItem>
                    <ContactIcon
                      src={InfoContainerData.Email.icon}
                      alt="Email"
                    />
                    <Typography
                      component="a"
                      variant="body1"
                      sx={{
                        fontSize: "20px",
                      }}
                      href={`mailto:${InfoContainerData.Email.Text} `}
                    >
                      {InfoContainerData.Email.Text}
                    </Typography>
                  </InfoItem>
                  <InfoItem>
                    <ContactIcon
                      src={InfoContainerData.Locations.icon}
                      alt="Location"
                    />
                    <Stack gap={2}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "20px",
                        }}
                      >
                        {InfoContainerData.Locations.Text}
                      </Typography>
                    </Stack>
                  </InfoItem>
                </Stack>
              </InfoContainer>
            </ContactInfoCardLeft>
          </Grid>
          <Grid item xs={12} lg={8} position="relative">
            <ContactFormCard id="contact" src={IMAGES.ContactBackground}>
              <Box
                sx={{
                  padding: 2,
                  paddingTop: 5,
                }}
              >
                <Box sx={{ marginBottom: 6.5 }}>
                  <ContactHeaderForm variant="h2">
                    Get In Touch
                  </ContactHeaderForm>
                  <ContactSubHeaderForm>
                    Feel free to drop us a line below!
                  </ContactSubHeaderForm>
                </Box>

                <Stack
                  component="form"
                  sx={{ width: "100%", gap: 4 }}
                  onSubmit={handleSubmit}
                >
                  {formErrors.length > 0 && (
                    <Alert
                      severity="error"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {formErrors.map((error) => (
                        <Typography component="li" key={error}>
                          {error}
                        </Typography>
                      ))}
                    </Alert>
                  )}
                  {showSuccess && (
                    <Alert
                      severity="success"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      <Typography key="error">
                        Message Was sent successfully
                      </Typography>
                    </Alert>
                  )}
                  <Stack
                    gap={2}
                    sx={{ flexDirection: { xs: "column", md: "row" } }}
                  >
                    <ContactFormInput
                      required
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      placeholder="Write Your First Name Here"
                      fullWidth
                      value={firstName}
                      onChange={handleInputChange}
                    />
                    <ContactFormInput
                      required
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      placeholder="Write Your Last Name Here"
                      fullWidth
                      value={lastName}
                      onChange={handleInputChange}
                    />
                  </Stack>
                  <ContactFormInput
                    required
                    id="Email"
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Write Your Email Here"
                    fullWidth
                    value={email}
                    onChange={handleInputChange}
                  />
                  <ContactFormInput
                    required
                    id="PhoneNumber"
                    label="Phone Number"
                    name="phoneNumber"
                    placeholder="Write Your Phone no. Here"
                    fullWidth
                    value={phoneNumber}
                    onChange={handleInputChange}
                  />
                  <ContactFormInput
                    id="filled-multiline-static"
                    label="Message"
                    name="message"
                    multiline
                    rows={4}
                    placeholder="Write Your Message Here"
                    fullWidth
                    value={message}
                    onChange={handleInputChange}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      width: "163px",
                      height: "36px",
                      backgroundColor: COLORS.TEXT_WHITE,
                      color: COLORS.PRIMARY_BLUE,
                      fontSize: "14px",
                      padding: "2px",
                      alignSelf: "flex-end",
                      "&:hover.MuiButton-root": {
                        color: COLORS.TEXT_WHITE,
                      },
                    }}
                    type="submit"
                  >
                    Send
                  </Button>
                </Stack>
              </Box>
            </ContactFormCard>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

export default Contact;
